<template>
  <div class="top">
    <div class="left" @click="index">
      <div class="logo"></div>
    </div>
    <div class="center">{{ lang.v.emaillogin }}</div>
    <div class="right" @click.stop="show = true">
      <div class="more"><img src="~@/assets/img/more.png" alt="" /></div>
    </div>
  </div>
  <div style="width: 100%; height: calc(100vh); background-color: #ffffff">
    <div class="APP_logo">
      <img src="~@/assets/img/email_login_logo.png" alt="" />
    </div>
    <div class="info">
      <div class="txt">
        <p>{{ lang.v.warning5 }}</p>
      </div>
      <el-form
        ref="ruleFormRef"
        :label-position="labelPosition"
        label-width="0rem"
        :model="userInfo"
        :rules="rules"
      >
        <el-form-item label="" class="user" prop="email">
          <div class="inputxt">
            <img src="~@/assets/img/email_login.png" alt="" />

            <el-input
              v-model="userInfo.email"
              :placeholder="lang.v.warning6"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="" class="user checkCode" prop="captcha">
          <div class="inputxt">
            <img src="~@/assets/img/code.png" alt="" />
            <template v-if="!usePassword">
              <el-input
                type="number"
                :placeholder="lang.v.enterCode"
                v-model="userInfo.captcha"
              />
              <div class="checktext">
                <p @click="getCheckCode" :disabled="counting">
                  {{ text }}
                </p>
              </div>
            </template>
            <template v-else>
              <el-input
                v-model="userInfo.password"
                :placeholder="lang.v.pleaseInputPwd"
              ></el-input>
            </template>
          </div>
        </el-form-item>
        <div
          style="color: #666"
          class="chackCode"
          v-show="usePassword"
          @click="usePassword = !usePassword"
        >
          {{ lang.v.useCodeLogin }}
        </div>
        <div
          style="color: #666"
          class="chackCode"
          v-show="!usePassword"
          @click="usePassword = !usePassword"
        >
          {{ lang.v.usePwdLogin }}
        </div>
        <el-form-item class="loginbox">
          <div class="login" @click="submit(ruleFormRef)">
            <el-button>{{ lang.v.Sign }}</el-button>
          </div>
        </el-form-item>
        <div class="agreement">
          {{ lang.v.text12 }}
          <el-link
            class="linkTxt"
            :underline="false"
            @click="yinsi"
            type="success"
          >{{ lang.v.Privacy }}
          </el-link>
          &
          <el-link
            class="linkTxt"
            :underline="false"
            @click="yonghu"
            type="success"
          >{{ lang.v.Agreement }}</el-link
          >
        </div>
      </el-form>
    </div>
  </div>
  <van-popup
    v-model:show="show"
    position="right"
    :style="{ width: '80%', height: '100%' }"
  >
    <div class="top_menu">
      <div class="left" @click="index">
        <div class="logo"><img src="~@/assets/img/logo_top.png" alt="" /></div>
      </div>
      <div class="right" @click="more">
        <div class="more"><img src="~@/assets/img/quxiao.png" alt="" /></div>
      </div>
    </div>
    <div
      v-if="userinfo == null ? false : true"
      style="
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 0.5rem;
      "
    >
      <div class="nav">
        <div class="left">
          <div class="picture"><img :src="userinfo.avatar" alt="" /></div>
          <div class="userId">UID:{{ userinfo.userId }}</div>
        </div>
        <div class="right">
          <img src="~@/assets/img/exit.png" alt="" @click="loginout" />
        </div>
      </div>
    </div>
    <div
      style="
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: calc(100vh - 1.13rem);
      "
    >
      <van-cell-group :border="false">
        <van-cell
          v-if="userinfo == null ? true : false"
          to="/login"
          :border="false"
          title-style="color:rgba(237, 147, 30, 1)"
          :title="lang.v.title_1"
          is-link
        />
        <van-cell :border="false" to="/" :title="lang.v.title_2" is-link />
        <van-cell
          :border="false"
          to="/wallet"
          :title="lang.v.title_3"
          is-link
        />
        <van-cell
          :border="false"
          @click="intelligent"
          :title="lang.v.title_4"
          is-link
        />
        <van-cell
          :border="false"
          to="/copyright"
          :title="lang.v.title_5"
          is-link
        />
        <van-cell
          :border="false"
          to="/community"
          :title="lang.v.title_6"
          is-link
        />
        <van-cell
          :border="false"
          to="/aboutus"
          :title="lang.v.title_7"
          is-link
        />
        <!-- <van-cell
          :border="false"
          v-if="userinfo == null ? false : true"
          @click="loginout"
          title="Log out"
          is-link
        /> -->
        <!-- <van-cell v-if="userinfo == null ? false : true" :border="false" title="订单记录" is-link />
            <van-cell v-if="userinfo == null ? false : true" :border="false" title="团队后台" is-link /> -->
      </van-cell-group>
    </div>
  </van-popup>
</template>

<script setup>
import { lang } from '@/lang';
import { onMounted, reactive, ref } from 'vue';
import {
  fetchCode,
  logout,
  fetchIMAuth,
  personal,
  fetchLogin,
} from '@/api/fetcher';
import router from '@/router';
import { clearLocalUser } from '@/utils/memory';
import message from '@/utils/message';
import dataCenter from '@/dataCenter';
import { baseUrl } from '@/api/index';
import { tokenController } from '@/manager/token';
const labelPosition = ref();
const ruleFormRef = ref();
const usePassword = ref(true);
const userInfo = ref({
  clientType: 'PC',
  invitationCode: '',
  email: '',
  captcha: '',
  password: '',
  check: [],
});
const yinsi = () => {
  router.push('/privacyPolicy');
};
const yonghu = () => {
  router.push('/userAgreement');
};

const symbol = ref(false);
const validateTelephone = (rule, value, callback) => {
  if (usePassword.value) {
    callback();
    return;
  }
  if (value === '') {
    callback(new Error(lang.v.warning6));
  } else if (/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(value)) {
    symbol.value = true;
    callback();
  } else {
    callback(new Error(lang.v.warning7));
  }
};
const validatePassword = (rule, value, callback) => {
  if (!usePassword.value) {
    callback();
    return;
  }
  if (value.length < 6 || value.length > 20) {
    callback(new Error(lang.v.pwdLimitTip));
  } else {
    callback();
  }
};
const validatecaptcha = (rule, value, callback) => {
  if (usePassword.value) {
    callback();
    return;
  }
  if (value.length === 0) {
    callback(new Error(lang.v.warning8));
  } else {
    callback();
  }
}; //验证码校验规则

const rules = reactive({
  email: [{ validator: validateTelephone, trigger: 'blur', reqired: true }],
  password: [{ validator: validatePassword, trigger: 'blur', reqired: true }],
  captcha: [{ validator: validatecaptcha, trigger: 'blur' }],
  check: [
    {
      type: 'array',
      required: true,
      trigger: 'change',
    },
  ],
});
let tag = true;
const text = ref(lang.v.text11);
const counting = ref(false);
const codeFetching = ref(false);
const getCheckCode = async () => {
  if (counting.value || codeFetching.value) {
    return;
  }
  if (tag && symbol.value) {
    let time = 90; //定义剩下的秒数
    text.value = time + lang.v.retrieve;
    counting.value = true;
    const timer = setInterval(() => {
      if (time === 0) {
        clearInterval(timer);
        text.value = lang.v.text11;
        counting.value = false;
      } else {
        time--;
        text.value = time + lang.v.retrieve;
      }
    }, 1000);
    codeFetching.value = true;
    await fetchCode(userInfo.value.email)
      .then(res => {
        if (res) {
          //showdilog 做提示
        } else {
          clearInterval(timer);
          text.value = lang.v.text11;
          counting.value = false;
        }
      })
      .finally(() => {
        codeFetching.value = false;
      });
  } else {
    message.warning(lang.v.warning9);
  }
}; //获取验证码
const allUsers = ref([]);
if (!localStorage.getItem('allUsers')) {
  allUsers.value = [];
} else {
  allUsers.value = dataCenter.allUsers.data;
}
function addObject(obj, token, refreshToken) {
  allUsers.value = dataCenter.allUsers.data;

  const newData = {
    ...obj,
    access_token: token,
    refresh_token: refreshToken,
  };

  if (allUsers.value) {
    const index = allUsers.value.findIndex(user => user?.userId === obj.userId);

    if (index !== -1) {
      // Update existing user and move to top
      allUsers.value.splice(index, 1, newData);
      allUsers.value.unshift(allUsers.value.splice(index, 1)[0]);
    } else {
      // Add new user to the top
      allUsers.value.unshift(newData);
    }
  } else {
    // Handle case where allUsers.value is not initialized
    allUsers.value = [newData];
  }

  dataCenter.allUsers.save(allUsers.value);
}
const submit = ruleFormRef => {
  if (!ruleFormRef) {
    return;
  }
  ruleFormRef.validate(valid => {
    if (valid) {
      const params = {
        clientType: userInfo.value.clientType,
        invitationCode: userInfo.value.invitationCode,
        email: userInfo.value.email,
        mobile: userInfo.value.mobile,
        captcha: userInfo.value.captcha,
        password: userInfo.value.password,
      };
      if (usePassword.value) {
        params.captcha = '';
      } else {
        params.password = '';
      }
      fetchLogin(params).then(res => {
        window.localStorage.setItem('access_token', res.tokenInfo.access_token);
        window.localStorage.setItem(
          'refresh_Token',
          res.tokenInfo.refresh_token
        );
        const access_token = res.tokenInfo.access_token;
        const refresh_Token = res.tokenInfo.refresh_token;
        tokenController.update(access_token, refresh_Token);
        personal().then(res => {
          userinfo.value = { ...res, email: userInfo.value.email };
          dataCenter.user.data = userinfo.value;
          window.localStorage.setItem(
            'userInfo',
            JSON.stringify(userinfo.value)
          );
          LoginIm();
          addObject(res, access_token, refresh_Token);
          router.push('/index');
        });
      });
    }
  });
}; //手机号登录

// Im介入
const LoginIm = async () => {
  const result = await fetchIMAuth();
  let IM = {
    data: {},
    url: '',
  };
  if (result) {
    const IMData = JSON.parse(result);
    IM.data = IMData;
    IM.url = `${baseUrl()}?ThirdLogin&tgid=${IMData.UserID}&token=${
      IMData.Token
    }&theme=dark&lang=${localStorage.getItem('lang') || 'en'}`;
    localStorage.setItem('IM', JSON.stringify(IM));
  }
};

const intelligent = () => {
  if (!localStorage.getItem('userInfo')) {
    router.push('/intelligent');
  } else {
    if (
      userinfo.value.talentCardType == 0 ||
      userinfo.value.talentCardType == null
    ) {
      router.push('/intelligent');
    } else {
      sessionStorage.setItem(
        'intelligent_id',
        JSON.stringify(userinfo.value.talentCardType)
      );
      router.push('/become_intelligent');
    }
  }
};
const loginout = () => {
  logout().then(() => {
    clearLocalUser();
    router.push('/login');
  });
};
const userinfo = ref({});
onMounted(async () => {
  try {
    const userInfoString = localStorage.getItem('userInfo');
    if (userInfoString && userInfoString !== 'undefined') {
      userinfo.value = JSON.parse(userInfoString);
    }
  } catch (e) {
    console.error('Error parsing userInfo from localStorage:', e);
  }
});
const more = () => {
  show.value = false;
}; // 进入菜单页面
const show = ref(false);
const index = () => {
  router.go(-1);
}; // 进入首页
</script>

<style lang="less" scoped>
.top {
  width: 100%;
  display: flex;
  padding-top: 0.22rem;
  padding-bottom: 0.1rem;
  position: fixed;
  top: 0;
  justify-content: space-between;
  box-shadow: 0rem 0.16rem 0.2rem 0rem rgba(237, 147, 30, 0.05);
  background-color: rgba(255, 255, 255, 0.8);

  .left {
    display: flex;

    .logo {
      width: 0.3rem;
      height: 0.3rem;
      margin-left: 0.14rem;
      background: url(~@/assets/img/left_jiantou.png);
      background-size: 100% 100%;
    }
  }

  .center {
    height: 0.25rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 0.18rem;
    color: #444444;
    line-height: 0.25rem;
  }

  .right {
    .more {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.14rem;

      img {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
  }
}

:deep(.van-dropdown-menu) {
  box-shadow: none;
  height: 0.46rem;

  .van-dropdown-menu__bar {
    box-shadow: none;
    height: 0.46rem;
  }
}

.APP_logo {
  width: 1rem;
  height: 1rem;
  border-radius: 0.1rem;
  margin: auto;
  padding-top: 1.6rem;

  img {
    width: 100%;
    height: 1rem;
    border-radius: 0.1rem;
  }
}

.info {
  width: 82.93%;
  margin: auto;
  margin-top: 0.2rem;
  // text-align: center;

  .txt {
    text-align: center;
    margin-bottom: 0.2rem;
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #858c9f;
    line-height: 0.16rem;
    padding-bottom: 0.2rem;
  }

  :deep(.el-form) {
    width: 100%;

    .user {
      .el-form-item__content {
        width: 100%;
        border-radius: 0.61rem;

        .inputxt {
          width: 100%;
          border: 0.02rem solid rgba(133, 140, 159, 0.15);
          border-radius: 0.61rem;

          .el-input {
            width: 80%;
            // height: 0.46rem;
            line-height: 0.5rem;
            border-radius: 0.61rem;

            .el-input__wrapper {
              width: 70%;
              // height: 0.46rem;
              line-height: 0.5rem;
              margin-top: -0.01rem;
              border-radius: 0.61rem;

              .input__inner {
                width: 70%;
                // height: 0.46rem;
                line-height: 0.5rem;
                margin-top: -0.01rem;
              }
            }
          }
        }

        .el-form-item__error {
          font-size: 0.14rem;
          // top: 80%;
        }
      }
    }

    .checkCode {
      .el-form-item__content {
        .el-form-item__error {
          font-size: 0.14rem !important;
        }

        .inputxt {
          .el-input {
            .el-input__wrapper {
              .el-input__inner {
                font-size: 0.14rem;
              }
            }
          }
        }
      }
    }

    .el-form-item {
      margin-bottom: 0.2rem;
    }
  }

  .ml-4 {
    width: 100%;
    height: 0.18rem;
    line-height: 0;
  }

  .loginbox {
    width: 100%;
    height: 0.48rem;
    margin-top: 0.2rem;

    :deep(.el-form-item__content) {
      line-height: 0rem !important;
    }

    .login {
      width: 100%;
      height: 0.48rem;
      font-size: 0;

      :deep(.el-button) {
        width: 100%;
        height: 0.48rem;
        border-radius: 0.27rem;
        padding: 0;
        border: none;
        background-image: linear-gradient(
          to right,
          rgba(244, 107, 69, 1),
          rgba(238, 168, 73, 1)
        );
        color: white;

        span {
          height: 0.2rem;
          font-size: 0.16rem;
        }
      }
    }
  }

  // border: $bor;
  .user {
    .inputxt {
      width: 3.11rem;
      height: 0.48rem;
      display: flex;
      align-items: center;
      // border: $bor;
      border-radius: 0.27rem;
      background-color: #ffffff;

      img {
        margin-left: 0.12rem;
        margin-right: 0.06rem;
      }

      .region {
        // width: 2.04rem;
        width: 0.44rem;
        // margin-bottom:0.1rem ;
        z-index: 9999;
        margin-right: 0.12rem !important;

        :deep(.region-items) {
          width: 2.04rem;
          margin-top: 0.1rem;
          left: 0.3rem;
        }
      }

      .front-number {
        width: 0.44rem;
        height: 0.2rem;
        font-size: 0.14rem;
        line-height: 0.2rem;
      }

      .down {
        width: 0.12rem;
        height: 0.12rem;
        margin-left: 0.06rem;
        position: relative;

        img {
          width: 0.12rem;
          height: 0.12rem;
          position: absolute;
          left: -0.24rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      :deep(.el-input) {
        .el-input__wrapper {
          box-shadow: none !important;
          .el-input__inner {
            font-size: 0.14rem;
          }
        }
      }

      .inviteCode {
        width: 1.5rem;
        height: 0.2rem;
      }

      .checktext {
        font-size: 0.14rem;
        position: absolute;
        right: 0.2rem;

        p {
          line-height: 0.2rem;
          color: #ed931e;
        }
      }
    }

    img {
      width: 0.24rem;
      height: 0.24rem;
      display: block;
      z-index: 99;
    }
  }
}

.chec {
  width: 100%;

  :deep(.el-checkbox-group) {
    margin-left: 0.06rem;
  }
}

.other {
  position: absolute;
  top: 6.62rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  // display: flex;
  .way {
    display: flex;
    justify-content: center;

    .weixin {
      width: 0.5rem;
      height: 0.5rem;
      margin: auto;
      margin-left: -0.06rem;
    }
  }
}

.email {
  width: 0.5rem;
  height: 0.5rem;
  position: relative;
  overflow: hidden;
  top: -1.1rem;
  left: 50%;
  transform: translateX(-50%);

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
}

:deep(.el-form-item__content) {
  align-items: center;

  .el-checkbox {
    width: 100%;
    height: 0.18rem;
    // border: $bor;
    // margin-top: 0.21rem;

    .el-checkbox__input {
      width: 0.2rem;
      height: 0.2rem;

      .el-checkbox__inner {
        border-radius: 0.14rem;
        width: 0.2rem;
        height: 0.2rem;
        border: 0.02rem solid #bdc1cb;
      }

      .el-checkbox__inner::after {
        width: 0.05rem;
        height: 0.09rem;
        left: 0.045rem !important;
        top: 0.005rem !important;
        border-bottom: 0.025rem solid white;
        border-right: 0.025rem solid white;
      }
    }

    .el-checkbox__label {
      width: 2.77rem;
      height: 0.18rem;
      line-height: 0.18rem;
      color: #858c9f;
      font-size: 0.14rem;
      padding-left: 0.06rem;

      .el-link {
        color: #ed931e;

        .el-link__inner {
          font-size: 0.14rem;
        }
      }
    }
  }
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background: #ed931e;
  border-color: #ed931e !important;
}

:deep(.el-checkbox__input.is-checked) {
  border-color: #ed931e;
}

.top_menu {
  width: 100%;
  display: flex;
  padding-top: 0.22rem;
  padding-bottom: 0.1rem;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);

  .left {
    display: flex;
    margin-left: calc(3.75% + 0.12rem);

    .logo {
      width: 0.97rem;
      height: 0.26rem;

      img {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
  }

  .right {
    .more {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: calc(3.75% + 0.12rem);

      img {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.nav {
  width: 92.5%;
  display: flex;
  height: 0.5rem;
  margin: auto;
  justify-content: space-between;

  .left {
    display: flex;

    .picture {
      width: 0.32rem;
      height: 0.32rem;
      margin-left: 0.12rem;
      margin-top: 0.09rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .userId {
      height: 0.5rem;
      font-size: 0.16rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      line-height: 0.5rem;
      margin-left: 0.12rem;
      background: linear-gradient(90deg, #f46b45 0%, #eea849 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .right {
    width: 0.24rem;
    height: 0.24rem;
    margin-right: 0.02rem;
    margin-top: 0.13rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.chackCode {
  font-size: 0.16rem;
}
.agreement {
  width: 82.93%;
  margin: auto;
  text-align: center;
  margin-top: 0.2rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 0.14rem;
  color: #858c9f;
  line-height: 0.18rem;

  :deep(.el-link) {
    color: #ed931e !important;
  }
}
</style>
